import React, { useState } from "react";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import bannerImg2 from "../images/Contact-us.jpg";
import Banner2 from "../Shared/Banner2/Banner2";

// import { useForm, ValidationError } from "@formspree/react";
import ContactForm from "./ContactForm";
import Helmet from "react-helmet";

const officeInfo = [
  {
    id: 1,
    icon: faMapMarkerAlt,
    heading: "Head Office",
    description: "Street #901, Plot #1216, 3rd Floor, Action Area, Kolkata- 700156, West Bengal, India.",
  },
  {
    id: 2,
    icon: faMapMarkerAlt,
    heading: "Country Office",
     description: "Avenue Louise 384, box 18, 1000 Brussels, Belgium.",
  },
  {
    id: 3,
    icon: faPhoneAlt,
    heading: "Phones",
    // description: "Cell: +91 95641 86761",
    description2: "Phone: +033 4603 0066",
  },
  {
    id: 4,
    icon: faPhoneAlt,
    heading: "Phones",
    // description: "Cell: +91 98749 78846",
     description2: "Phone: +033 4603 0066",
  },
  {
    id: 5,
    icon: faEnvelope,
    heading: "Email",
    description: "whsc.in@gmail.com",
   // description2: "",
  },
  {
    id: 6,
    icon: faEnvelope,
    heading: "Email",
    description: "whsc@worldhindustrugglecommittee.org",
  },
];

const Contact = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [toast, setToast] = useState(false);
  // const [state, handleSubmit] = useForm("mrgrvkld");
  const handleShow = () => {
    setShow(true);
    setToast(true);
  };

  return (
    <section className=" ">
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Contact | World Human Struggle Committee</title>
        <meta name="title" content="Contact | World Human Struggle Committee" />
        <meta
          name="keywords"
          content="contact, find us, communicate, messege, email, location"
        />
        <meta
          name="description"
          content="Contact us, become a member and help the struggled people around the world"
        />
        <meta name="robots" content="index,follow" />
        <meta name="url" content="http://www.whsc.com/contact" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta
          itemprop="name"
          content="Contact | World Human Struggle Committee"
        />
        <meta
          itemprop="description"
          content="Contact us, become a member and help the struggled people around the world"
        />
      </Helmet>
      <Banner2
        bannerImg={bannerImg2}
        bannerTitle="Contact"
        link="Home"
        link2="Contact"
        url1="/"
      />
      <div className="custom-container">
        <div className="row  border-dark py-5 ">
          <Fade left>
            <div className="col-md-6 py-4">
              <div className="heading-style">
                <span>Write Us </span>
              </div>
              <p className="py-4 secondary-color">
                Have any questions, reports, news or suggestion ? Please feel free to contact us.
                We are always open to hear from you.
              </p>

              <ContactForm />

            </div>
          </Fade>
          <Fade right>
            <div className="col-md-6 py-4">
              <div className="heading-style2">
                <span>Our Offices </span>
              </div>
              <p className="py-4 secondary-color">
                Don't hesitate to contact us at the address below for an official visit or meet up with us.
              </p>

              <div className="row">
                {officeInfo.map((info) => (
                  <div className="col-md-6 row py-3 px-4">
                    <div className="col-md-3 pb-2">
                      <FontAwesomeIcon
                        icon={info.icon}
                        className=" primary-color"
                      />
                    </div>
                    <div className="col-md-9 p-0 office-desc ">
                      <h6 className=" fw-bold  lh-1 office-heading">
                        {info.heading}
                      </h6>
                      <div className="py-2 secondary-color">
                        <address className="">
                          {info.description} <br /> {info.description2}{" "}
                        </address>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Fade>
        </div>
      </div>

      <div class=" w-100 map-section pt-5">
        <iframe width="100%"
          height="450"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=VILLA-66,+IDEAL+VILLAS,+Action+Area+I,+Newtown,+Dhapa+Manpur+P,+West+Bengal+700098,+India%20india&t=&z=17&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
    </section>
  );
};

export default Contact;
