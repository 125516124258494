
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./../Card.css";
import bannerImg2 from "../../../images/boardDirector.jpg";
import Banner2 from "../../../Shared/Banner2/Banner2";
// import ContactAction from "../../../Shared/ContactAction/ContactAction";
import Brahmidevi from "../../../images/our-people/legal-counsel/Brahmidevi-Swamiji.png";
import Padmavati from "../../../images/our-people/legal-counsel/Padmavati-Sushant-Shelatkar.png";
import Mahammad from "../../../images/our-people/legal-counsel/Mahammad-Abu-Hanif.png";
import Saikat from "../../../images/our-people/legal-counsel/Saikat-Basu.png";
import Hiranmoy from "../../../images/our-people/legal-counsel/hiranmoy-debnath.jpg";

const BoardMember = [
  {
    name: "ADV Brahmidevi Swamiji",
    designation: "Legal Counsel and Education Advisor",
    personImg: Brahmidevi,
    country: "India",
  },
  {
    name: "Padmavati Sushant Shelatkar",
    designation: "Legal Counsel Advisor",
    personImg: Padmavati,
    country: "India",
  },
  {
    name: "Mahammad Abu Hanif",
    designation: "Legal Counsel Advisor",
    personImg: Mahammad,
    country: "Bangladesh",
  },
  {
    name: "Saikat Basu",
    designation: "Legal Counsel Advisor",
    personImg: Saikat,
    country: "India",
  },
  {
    name: "Hiranmoy Debnath",
    designation: "Law Secretary",
    personImg: Hiranmoy,
    country: "India",
  },



];

const SupportDesk = () => {
  const [loadMore, setLoadMore] = useState(6);
  const LoadMoreHandle = () => {
    setLoadMore((prevValue) => prevValue + 3);
  };
  return (
    <section>
      <Banner2
        bannerImg={bannerImg2}
        bannerTitle="Legal Support Desk"
        link="Our People"
        link2="Support-Desk"
        url1="/ourPeople"
        className="position-relative"
      />
      <div className="container">
        <div className="py-5">
          <div className="text-center ">
            <div className="heading-style4">
              <h2 className="text-black">LEGAL SUPPORT DESK</h2>
            </div>
            {/* <p>
              PI is governed by a Board of Directors which is responsible for
              the overall strategy and governance of the organisation.
            </p> */}

            <div className="py-5 row">
              {BoardMember.slice(0, loadMore).map((value) => (
                <div className="col-md-4">
                  <div className="director-card">
                    <div className="">
                      <img src={value.personImg} alt="" className="teamImg" />
                    </div>

                    <div className="infoo">
                      <div className="pb-5">
                        <h4 className="">{value.name}</h4>
                        <small>{value.designation}</small> <br />
                        <small>Country: {value.country}</small>
                      </div>

                      {/* <div className="card-overlay">
                        <p className="pt-2">
                          PI is governed by a Board PI is governed by a Board of
                          Directors in India
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button onClick={LoadMoreHandle} className="primary-btn2">
              Load More
            </button>
          </div>
        </div>
      </div>

      <div className="TopSubFooterBg">
        <div className="custom-container">
          <div className="row TopSubFooter">
            <div className="col-md-8 my-auto">
              <h3 className="my-auto text-uppercase mb-2">Become a Part of Our Team</h3>
            </div>
            <div className="my-auto col-md-4">
              <Link to="/membership"><button className="">Join Us Now</button></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SupportDesk;

