import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import GoTop from './components/Shared/GoTop/GoTop';
import Navbar from './components/Shared/Navbar/Navbar';
import Footer from './components/Shared/Footer/Footer';
import CookieConsent from 'react-cookie-consent';
import Home from './components/Home/Home';
import OurPeopleData from './components/About/OurPeople/OurPeopleData';
import Mission from './components/About/MissionAndVision/MissionVision';
import Vission from './components/Mission&Vission/Vission';
import Advisors from './components/About/OurPeople/OurPeopleCards/Advisors';
import AffiliatedOrganization from './components/AffiliatedOrganization/AffiliatedOrganization';
import Management from './components/About/OurPeople/OurPeopleCards/Management';
import About from './components/About/About/About';
import SocialAdvisor from './components/About/OurPeople/OurPeopleCards/SocialAdvisor';
import ProtectionDesk from './components/About/OurPeople/OurPeopleCards/ProtectionDesk';
import MediaCell from './components/About/OurPeople/OurPeopleCards/MediaCell';
import ExecutiveCommittee from './components/About/OurPeople/OurPeopleCards/ExecutiveCommittee';
import SupportDesk from './components/About/OurPeople/OurPeopleCards/SupportDesk';
import Patron from './components/About/OurPeople/OurPeopleCards/Patron';
import CountryCommittee from './components/About/OurPeople/OurPeopleCards/CountryCommittee';
import GalleryComponent from './components/Gallery/GalleryComponent';
import WorkWithUs from './components/About/WorkWithUs/WorkWithUs';
import OurStory from './components/About/OurStory/OurStory';
import Donor from './components/About/Donors/Donor';
import FAQ from './components/About/FAQ/FAQ';
import DonateUs from './components/Donate/DonateUs';
import Contact from './components/Contact/Contact';
import Membership from './components/About/Membership/Membership';
import MissionVision from './components/About/MissionAndVision/MissionVision';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Terms from './components/Terms/Terms';
import CampaignMain from './components/Campaign/CampaignMain';
import WeStandsFor from './components/WeStandsFor/WeStandsFor';
import BlogDetails from './components/WeStandsFor/BlogDetails';
import OurActivity from './components/Home/OurActivity/OurActivity';
import Country from './components/CountryData/Country';
import HelpThePeopleDetails from './components/Home/HelpThePeopleData/HelpThePeopleDetails';
import Pakistan from './components/CountryData/Pakistan';
import GetCertificate from './components/Certificates/GetCertificate';
import Error from './components/Shared/Error/Error';
import"./App.css";

function App() {
  return (
    <div className="position-relative">
      <Router>
        <ScrollToTop />
        <GoTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ourPeople" element={<OurPeopleData />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/vision" element={<Vission />} />
          <Route path="/advisors" element={<Advisors />} />
          <Route path="/affiliated-organization" element={<AffiliatedOrganization />} />
          <Route path="/management" element={<Management />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/religious-and-social-advisor" element={<SocialAdvisor />} />
          <Route path="/security-board" element={<ProtectionDesk />} />
          <Route path="/media-cell" element={<MediaCell />} />
          <Route path="/executive-committee" element={<ExecutiveCommittee />} />
          <Route path="/support-desk" element={<SupportDesk />} />
          <Route path="/patron" element={<Patron />} />
          <Route path="/country-committee" element={<CountryCommittee />} />
          <Route path="/gallery" element={<GalleryComponent />} />
          <Route path="/workWithUs" element={<WorkWithUs />} />
          <Route path="/ourStory" element={<OurStory />} />
          <Route path="/ourDonors" element={<Donor />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/donate" element={<DonateUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/mission-and-vision" element={<MissionVision />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/campaign" element={<CampaignMain />} />
          <Route path="/we-stands-for" element={<WeStandsFor />} />
          <Route path="/we-stands-for/:slug/:id" element={<BlogDetails />} />
          <Route path="/our-activity/:title" element={<OurActivity />} />
          <Route path="/country-data/:title" element={<Country />} />
          <Route path="/help-the-people-details/:title" element={<HelpThePeopleDetails />} />
          <Route path="/Pakistan" element={<Pakistan />} />
          <Route path="/get-certificate" element={<GetCertificate />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </Router>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        style={{ background: "#273036", padding: "0 30px " }}
        buttonStyle={{
          color: "#273036",
          background: "#ffb422",
          fontWeight: "bold",
        }}
        expires={365}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
}

export default App;
