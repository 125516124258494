import React from "react";

import "./Home.css";
import HeroSection from "./HeroSection";
import HomeCard from "./HomeCard";
import Registration from "./Registration";
import WhoWeAre from "./WhoWeAre";
import WhatWeDo from "./WhatWeDo";
import DevelopmentGoal from "./DevelopmentGoal";
import Activities from "./Activities";
import TopFooter from "../Shared/Footer/TopFooter";
import HelpThePeople from "./HelpThePeople";

const Home = () => {
  return (
    <section className=" overflow-hidden">
      <HeroSection />
      <HomeCard />
      <WhoWeAre />
      <Activities />
      <WhatWeDo />
      <DevelopmentGoal />
      <HelpThePeople />
      <Registration />
      <TopFooter />
    </section>
  );
};

export default Home;


