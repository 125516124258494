import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    rootElement,
    <React.StrictMode>
        <App />  
    </React.StrictMode>
  );
} else {
 
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode> 
        <App />     
    </React.StrictMode>
   
  );
}

reportWebVitals();

