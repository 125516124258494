import React, { useState, useEffect } from "react";
import "./CampaignSection.css";
import { Card } from "react-bootstrap";
import AllCampaign from "./AllCampaign";


const CampaignSection = () => {
  // ?Date Formate //
  var moment = require("moment");

  const [campaignSection, setCampaignSection] = useState([]);
  useEffect(() => {
    fetch("https://whsc.in/wp-json/wp/v2/posts?categories=699")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCampaignSection(data);
      });
  }, []);

  return (
    <section className="py-5">
      <div className="container pt-5">
        <div className="heading-style4">
          <h2 className="text-dark">RUNNING CAMPAIGNS</h2>
        </div>
        <div className="row g-3">
          {campaignSection.slice(0, 4).map((data) => (
            <Card
              id="animation"
              className="border-0 bg-transparent col-lg-3 col-md-4 col-sm-12"
            >
              <div className="single-campaign-card bg-white">
                <div className="overflow-hidden">
                  <Card.Img
                    variant="top"
                    src={data.jetpack_featured_media_url}
                    className="campaignImg p-2"
                  />
                </div>
                <Card.Body className="px-3">
                  <Card.Text className="text-dark fw-bold">
                    <small>{moment(data.date).format("D MMM YYYY")}</small>
                  </Card.Text>
                  <Card.Title className="text-dark fs-6 fw-bold pb-4 cardDetailEclipse">
                    {data.title.rendered}
                  </Card.Title>
                </Card.Body>
                <Card.Footer className="text-center border-0 p-0">
                  <a
                    className="runningCampaign"
                    href={data.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Campaign Details
                  </a>
                </Card.Footer>
              </div>
            </Card>
          ))}
        </div>
      </div>
      <div className="py-5"></div>

      <div className="sectionBG py-5">
        <div>
          <AllCampaign />
        </div>
      </div>
    </section>
  );
};

export default CampaignSection;
