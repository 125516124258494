import React from "react";
import { Link } from "react-router-dom";
import "./../Card.css";
import Banner2 from "../../../Shared/Banner2/Banner2";
import bannerImg2 from "../../../images/global.jpg";
import workingPresident from "../../../images/our-people/executive-committee/aditya-tandon-whsc.png";
import alexander from "../../../images/our-people/executive-committee/alexander-shapiro-soliman.png";
import debasis from "../../../images/our-people/executive-committee/debashis-basu.png";
import suman from "../../../images/our-people/executive-committee/suman-thakur-whsc.png";
import anmol from "../../../images/our-people/executive-committee/anmol-rajesh-bagaria.png";
import sheraz from "../../../images/our-people/executive-committee/sheraz-hadayat.png";
import aniruddha from "../../../images/our-people/executive-committee/aniruddha.png";
import shyamol from "../../../images/our-people/executive-committee/shyamol.png";
import satyaprakash from "../../../images/our-people/executive-committee/satyaprakash.png";
import bablu from "../../../images/our-people/executive-committee/bablu.jpg";
import emmanuel from "../../../images/our-people/executive-committee/emmanuel.png";
import jude from "../../../images/our-people/executive-committee/jude.jpg";
import abhishek from "../../../images/our-people/executive-committee/abhishek.png";
import ritesh from "../../../images/our-people/executive-committee/ritesh-kumer-mishra.png";
import juwel from "../../../images/our-people/executive-committee/juwel-chakrabarty.jpg";
import meenu from "../../../images/our-people/executive-committee/meenu-padha.png";
import namita from "../../../images/our-people/executive-committee/namita-ghimire.png";
import sandeep from "../../../images/our-people/executive-committee/sandip-sayapuram.png";
import babalola from "../../../images/our-people/executive-committee/babalola-maytwa-ezekiel.png";
import jose from "../../../images/our-people/executive-committee/jose-luis.png";
import hayane from "../../../images/our-people/executive-committee/hayane-jaouad.png";
import jamal from "../../../images/our-people/executive-committee/jamal-lhayad.png";
import gan from "../../../images/our-people/executive-committee/gan-chai-leong.png";
import michelle from "../../../images/our-people/executive-committee/michelle-wagth-jacob.png";
import sivanesan from "../../../images/our-people/executive-committee/sivanesan-tamilselvam.png";
import sakkaravathi from "../../../images/our-people/executive-committee/sakkaravathi-pandiar.png";
import nakkeeran from "../../../images/our-people/executive-committee/nakkeeran-suntharalingam.png";
import Javier from "../../../images/our-people/executive-committee/Javier-Otto-Jorge-Gold-Ferrari.png";
import ojas from "../../../images/our-people/executive-committee/Ojas-Kumar-Singh.png";
import sachin from "../../../images/our-people/executive-committee/Sachin-Prasad.png";
import subhan from "../../../images/our-people/executive-committee/Subham-Kumari.png";
import rajinder from "../../../images/our-people/executive-committee/Rajinder-Singh.png";
import Jessie from "../../../images/our-people/executive-committee/ZLT-Jessie-Len-Argabio.png";
import raju from "../../../images/our-people/executive-committee/Late-Raju-Saluia.png";
import sumon from "../../../images/our-people/executive-committee/Sumon-Kumar-Roy.png";
import bacha from "../../../images/our-people/executive-committee/Bacha-Babu-Mistry.png";
import negendra from "../../../images/our-people/executive-committee/Nagendra-P.png";
import shamiron from "../../../images/our-people/executive-committee/Shamiron-Baral.png";
import saiful from "../../../images/our-people/executive-committee/Saiful-Bin-Hanif.png";
import manohar from "../../../images/our-people/executive-committee/Manohar-Paresh-Nath-Roy.png";
import pradip from "../../../images/our-people/executive-committee/Pradip-Biswas.png";
import monoj from "../../../images/our-people/executive-committee/Manoj-Kallan-Bose.png";
import tapa from "../../../images/our-people/executive-committee/Tapa-Prakash-Sena.png";
import omar from "../../../images/our-people/executive-committee/Omar-Ait-Khouya.png";
import salam from "../../../images/our-people/executive-committee/Salam-Nawaz.png";
import nirmal from "../../../images/our-people/executive-committee/nirmal-kumer-mondol.png";
import kevin from "../../../images/our-people/executive-committee/Kevin-Yehuda-Steinori.png";
import pandya from "../../../images/our-people/executive-committee/Pandya-Devram.png";
import joni from "../../../images/our-people/executive-committee/joni.jpg";
import disha from "../../../images/our-people/executive-committee/disha.JPG";
import rani from "../../../images/our-people/executive-committee/Roni-ghose.jpg";



const ExecutiveCommitteeData = [
  {
    name: "Aditya Tandon",
    designation: "Working President",
    personImg: workingPresident,
    country: "India",
    details: "",
  },
  {
    name: "Dr. Alexander Shapiro Soliman",
    designation: "Vice President and Political Advisor",
    personImg: alexander,
    country: "Azerbaijan",
  },
  {
    name: "Debasis Basu",
    designation: "Vice President",
    personImg: debasis,
    country: "Bangladesh",
  },
  {
    name: "Suman Thakur",
    designation: "Vice President",
    personImg: suman,
    country: "Italy",
  },
  {
    name: "Dr. Anmol Rajesh Bagaria",
    designation: "Vice President",
    personImg: anmol,
    country: "India",
  },
  {
    name: "Sheraz Hadayat",
    designation: "Vice President",
    personImg: sheraz,
    country: "Pakistan",
  },
  {
    name: "Aniruddha Chakraborty",
    designation: "Vice President",
    personImg: aniruddha,
    country: "India",
  },
  {
    name: "Shyamol Kanti Nag",
    designation: "Vice President",
    personImg: shyamol,
    country: "Bangladesh",
  },
  {
    name: "Satyaprakash Tiwari",
    designation: "Vice President",
    personImg: satyaprakash,
    country: "Singapore",
  },
  {
    name: "Bablu Mondal",
    designation: "Vice President",
    personImg: bablu,
    country: "Bangladesh",
  },
  {
    name: "Dr. Emmanuel M. Cabusao",
    designation: "Vice President",
    personImg: emmanuel,
    country: "Philippines",
  },
  {
    name: "Jude Nimalan",
    designation: "Vice President",
    personImg: jude,
    country: "Srilanka",
  },
  {
    name: "Abhishek Gupta",
    designation: "Ex-Vice President",
    personImg: abhishek,
    country: "India",
  },
  {
    name: "Mr. Ritesh Kumar Mishra",
    designation: "General Secretary",
    personImg: ritesh,
    country: "India",
  },
  {
    name: "Juwel Chakraborti",
    designation: "Asst. General Secretary",
    personImg: juwel,
    country: "Bangladesh",
  },
  {
    name: "Disha Basu",
    designation: "Spokesperson",
    personImg: disha,
    country: "India",
  },

  {
    name: "Meenu Padha",
    designation: "Human Rights Secretary",
    personImg: meenu,
    country: "India",
  },
  {
    name: "Namita Ghimire",
    designation: "Human Rights Secretary",
    personImg: namita,
    country: "Nepal",
  },
  {
    name: "Sandeep Sayapuram",
    designation: "Human Rights Secretary",
    personImg: sandeep,
    country: "India",
  },
  {
    name: "Babalola Mayiwa Ezekiel",
    designation: "Human Rights Secretary",
    personImg: babalola,
    country: "Nigeria",
  },
  {
    name: "Jose Luis",
    designation: "Human Rights Secretary",
    personImg: jose,
    country: "Venezuela",
  },
  {
    name: "Hayane Jaouad",
    designation: "Human Rights Secretary",
    personImg: hayane,
    country: "Morocco",
  },
  {
    name: "Jamal Lhayad",
    designation: "Human Rights Secretary",
    personImg: jamal,
    country: "Morocco",
  },
  {
    name: "Gan Chai Leong",
    designation: "Human Rights Secretary",
    personImg: gan,
    country: "Malaysia",
  },
  {
    name: "Michelle Wagih Jacob",
    designation: "Human Rights Secretary",
    personImg: michelle,
    country: "",
  },
  {
    name: "Mr Sivanesan Tamilselvam",
    designation: "Public Relation Secretary",
    personImg: sivanesan,
    country: "Malaysia",
  },
  {
    name: "Sakkaravathi Pandiar",
    designation: "Public Relation Secretary",
    personImg: sakkaravathi,
    country: "India",
  },
  {
    name: "Nakkeeran Suntharalingam",
    designation: "Public Relation Secretary",
    personImg: nakkeeran,
    country: "Srilanka",
  },
  {
    name: "Dr Ojas Kumar Singh",
    designation: "Health Secretary",
    personImg: ojas,
    country: "India",
  },
  {
    name: "Dr Sachin Prasad",
    designation: "Health Secretary",
    personImg: sachin,
    country: "India",
  },
  {
    name: "Dr Subham Kumari",
    designation: "Health Secretary",
    personImg: subhan,
    country: "India",
  },
  {
    name: "Javier Otto Jorge Gold Ferrari",
    designation: "Health Secretary",
    personImg: Javier,
    country: "Peru",
  },
  {
    name: "Dr Rajinder Singh",
    designation: "Special Secretary Education And Protocols",
    personImg: rajinder,
    country: "India",
  },
  {
    name: "ZLT Jessie Len E Argabio",
    designation: "Security Secretary",
    personImg: Jessie,
    country: "Philippines",
  },
  {
    name: "Late Raju Saluia",
    designation: "Religious Secretary",
    personImg: raju,
    country: "India",
  },
  {
    name: "Sumon Kumar Roy",
    designation: "Law And Policy Secretary",
    personImg: sumon,
    country: "Bangladesh",
  },
  {
    name: "Bacha Babu Mistry",
    designation: "Law And Policy Secretary",
    personImg: bacha,
    country: "India",
  },
  {
    name: "Nagendra P",
    designation: "Political Secretary",
    personImg: negendra,
    country: "India",
  },
  {
    name: "Shamiron Baral",
    designation: "Asst. Organizing Secretary",
    personImg: shamiron,
    country: "Bangladesh",
  },
  {
    name: "Saiful Bin Hanif",
    designation: "Asst. Cultural Secretary",
    personImg: saiful,
    country: "Bangladesh",
  },
  {
    name: "Dr Manohar Paresh Nath Roy",
    designation: "Secretary Social And Welfare",
    personImg: manohar,
    country: "India",
  },
  {
    name: "Pradip Biswas",
    designation: "Secretary",
    personImg: pradip,
    country: "Bangladesh",
  },
  {
    name: "Manoj Kallan Bose",
    designation: "Secretary",
    personImg: monoj,
    country: "Bangladesh",
  },
  {
    name: "Tapa Prakash Sena",
    designation: "Secretary",
    personImg: tapa,
    country: "India",
  },
  {
    name: "Omar Ait Khouya",
    designation: "Join Secretary",
    personImg: omar,
    country: "Morocco",
  },
  {
    name: "Salam Nawaz",
    designation: "Join Secretary",
    personImg: salam,
    country: "Bangladesh",
  },
  {
    name: "Al-Amin Shaikh Johnny",
    designation: "Publicity Secretary",
    personImg: joni,
    country: "Bangladesh",
  },

  {
    name: "Nirmal Kumar Mondol",
    designation: "Treasurer",
    personImg: nirmal,
    country: "India",
  },

  {
    name: "Kevin Yehuda Steinori",
    designation: "Office Secretary",
    personImg: kevin,
    country: "Israel",
  },
  {
    name: "Pandya Devram",
    designation: "Executive Member",
    personImg: pandya,
    country: "India",
  },
   {
    name: "Rani Ghose",
    designation: "Polotical Secretary of President",
    personImg: rani,
    country: "India",
  },
];

const ExecutiveCommittee = () => {
  return (
    <section>
      <Banner2
        bannerImg={bannerImg2}
        bannerTitle="WHSC Executive Committee"
        link="Our People"
        link2="Executive Committee"
        url1="/ourPeople"
        className="position-relative"
      />
      <div className="container">
        <div className="py-5">
          <div className="text-center ">
            <h1>Executive Committee</h1>
            {/* <p>
              PI is governed by a Board of Directors which is responsible for
              the overall strategy and governance of the organisation.
            </p> */}
            <div className="row g-4 pt-5">
              {ExecutiveCommitteeData.map((value) => (
                <div className="col-md-4">
                  <div className="globalMemberCard">
                    {/* <div className="shape2"></div> */}
                    <div className="global-img-box">
                      <img src={value.personImg} alt="" className="teamImg" />
                    </div>

                    <div className="global-team-content">
                      <h4 className="primary-color">{value.name}</h4>
                      <h6>{value.designation}</h6> <br />
                      <div className="global-card-details">
                        <strong>Country: {value.country}</strong>
                        {/* <p className="pt-2">
                          {value.details}
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="TopSubFooterBg">
        <div className="custom-container">
          <div className="row TopSubFooter">
            <div className="col-md-8 my-auto">
              <h3 className="my-auto text-uppercase mb-2">Become a Part of Our Global Team</h3>
            </div>
            <div className="my-auto col-md-4">
              <Link to="/membership"><button className="">Join Us Now</button></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExecutiveCommittee;